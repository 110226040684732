import { Label, Input } from "@src/common/components";
import React, { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";

interface ControllerInputProps {
  name: string;
  label: string;
  className?: string;
  inputType?: string;
}

const ControllerInput: FC<ControllerInputProps> = ({
  name,
  label,
  className,
  inputType = "text",
}) => {
  const {
    control,
    formState: { errors, dirtyFields },
  } = useFormContext();

  const isTouched = dirtyFields[name];
  const hasError = !!errors[name];
  const isValid = isTouched && !hasError;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <div className={className}>
          <Label className="block text-xs font-medium text-gray-700 truncate">
            {label}
          </Label>
          <Input
            size={"mini"}
            value={field.value}
            onChange={(event) => field.onChange(event.currentTarget.value)}
            onBlur={field.onBlur}
            type={inputType}
            error={hasError}
            positive={dirtyFields[name] && !hasError}
          />
        </div>
      )}
    />
  );
};

export default ControllerInput;
