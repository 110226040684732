import { Tooltip } from "@src/common/components";
import { generateTooltipContent } from "../utils/shipmentRowFormatter";
import {
  ArchiveBoxIcon,
  ScaleIcon,
  ServerStackIcon,
} from "@heroicons/react/24/outline";

type PackageProps = {
  numPackages?: number;
  packageSize?: string;
  packageWeightInPounds?: number;
};

type PackageTooltipProps = {
  pkg?: PackageProps;
};

export const PackageTooltip: React.FC<PackageTooltipProps> = ({ pkg }) => {
  const hasPackageInfo =
    pkg &&
    (pkg.numPackages ||
      (pkg.packageSize && pkg.packageSize.trim() !== "") ||
      pkg.packageWeightInPounds);

  if (hasPackageInfo) {
    return (
      <Tooltip content={generateTooltipContent(pkg)}>
        <p className="font-normal truncate">
          {pkg.numPackages && (
            <span className="mr-1">
              <ArchiveBoxIcon className="inline h-4 w-4" /> {pkg.numPackages}
            </span>
          )}
          {pkg.packageSize && (
            <span className="mr-1">
              <ServerStackIcon className="inline h-4 w-4" /> {pkg.packageSize}
            </span>
          )}
          {pkg.packageWeightInPounds && (
            <span className="mr-1">
              <ScaleIcon className="inline h-4 w-4" />{" "}
              {pkg.packageWeightInPounds} lbs
            </span>
          )}
        </p>
      </Tooltip>
    );
  }
  return <p className="font-normal truncate text-slate-400">NA</p>;
};
