import { FunnelIcon } from "@heroicons/react/24/outline";
import { FunnelIcon as FunnelIconSolid } from "@heroicons/react/24/solid";
import { StatefulPopover } from "baseui/popover";
import { useEffect, useRef, useState } from "react";
import CourieButton from "./Button";
import { LayersManager } from "baseui/layer";
import { Filter } from "@src/shipments/context/TableControlContext";

export default function TableFilterPopover({
  children,
  filters,
  isGroupFilter = false,
}: {
  children?: React.ReactNode;
  filters?: Filter[];
  isGroupFilter?: boolean;
}) {
  const btnRef = useRef<HTMLButtonElement>(null);
  const [groupedFilters, setGroupedFilters] = useState<{
    [key: string]: Filter[];
  }>({});

  useEffect(() => {
    if (filters) {
      const grouped = filters.reduce((acc, filter) => {
        if (filter.value) {
          if (!acc[filter.type]) {
            acc[filter.type] = [];
          }
          acc[filter.type].push(filter);
        }
        return acc;
      }, {} as { [key: string]: Filter[] });

      setGroupedFilters(grouped);
    }
  }, [filters]);

  const filterCount = isGroupFilter
    ? Object.keys(groupedFilters).length
    : filters?.filter((filter) => filter.value).length || 0;

  const isFiltersApplied = filterCount > 0;

  return (
    <LayersManager zIndex={20}>
      <StatefulPopover
        content={() => <>{children}</>}
        returnFocus
        autoFocus
        placement="bottom"
      >
        <CourieButton
          ref={btnRef}
          size="xs"
          color={isFiltersApplied ? "success" : "secondary"}
          className="hover:bg-slate-100 border p-0"
        >
          {isFiltersApplied ? (
            <>
              <FunnelIconSolid className="h-4 w-4 mr-1" />
              <span>
                {filterCount} {filterCount === 1 ? "filter" : "filters"} applied
              </span>
            </>
          ) : (
            <>
              <FunnelIcon className="h-4 w-4 mr-1" />
              <span>Filters</span>
            </>
          )}
        </CourieButton>
      </StatefulPopover>
    </LayersManager>
  );
}
