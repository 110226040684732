import { Badge, Tooltip } from "flowbite-react";

export default function RoundTripShipmentLabel() {
  return (
    <Tooltip content="Round Trip">
      <Badge color="warning" size="xs">
        RT
      </Badge>
    </Tooltip>
  );
}
