import { ShipmentType } from "@api/graphql/generated/generated-types";

export const shipmentTypeArray = enumToArrayOfObjects(ShipmentType);

export function enumToArrayOfObjects<T extends Record<string, string>>(
  enumeration: T
): { id: string; label: string }[] {
  return Object.entries(enumeration).map(([id, label]) => ({ id, label }));
}

export function returnObjectForSelectValue(getValues): any[] {
  const selectValue = getValues(`type`);
  if (typeof selectValue === "string") {
    const shipmentsTypeFound = shipmentTypeArray.find(
      (item) => item.label === selectValue
    );
    if (shipmentsTypeFound) {
      return [shipmentsTypeFound];
    }
  }
  if (selectValue) {
    return selectValue;
  }
  return [];
}
