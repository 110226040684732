import {
  CostCenter,
  Order,
  useGetCostCentersLazyQuery,
} from "@api/graphql/generated/generated-types";
import { Label, Select } from "@src/common/components";
import { showErrorToast } from "@src/common/lib/NetworkErrorHandling";
import { useCourieStore } from "@src/common/lib/store";
import React, { useEffect, useState, useCallback } from "react";
import { Controller } from "react-hook-form";

type Props = {
  control: any;
  setValue: any;
  order: Order | undefined;
};

function CostCenterSelector({ control, setValue, order }: Props) {
  const { showToast } = useCourieStore();

  const [retrievedCostCenters, setRetrievedCostCenters] = useState<
    CostCenter[]
  >([]);
  const [endCustomerId, setEndCustomerId] =
    useState<string | undefined>(undefined);
  const [costCenter, setCostCenter] =
    useState<CostCenter | undefined>(undefined);

  const [fetchCostCenters, { loading, error }] = useGetCostCentersLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setRetrievedCostCenters(data.costCenters || []);
    },
    onError: (err) => {
      showErrorToast(err, showToast);
    },
  });

  const fetchAndSetCostCenters = useCallback(() => {
    if (endCustomerId) {
      fetchCostCenters({
        variables: { endCustomerIds: endCustomerId },
      });
    }
  }, [endCustomerId, fetchCostCenters]);

  useEffect(() => {
    if (!order) {
      return;
    }
    const selectedCostCenter = order.costCenter as CostCenter | undefined;
    const selectedEndCustomerId = order.endCustomer.id;
    if (selectedEndCustomerId) {
      setEndCustomerId(selectedEndCustomerId);
      fetchAndSetCostCenters();
    }
    if (selectedCostCenter) {
      setCostCenter(selectedCostCenter);
      setValue("costCenterId", selectedCostCenter.id);
    }
  }, [order, fetchAndSetCostCenters, setValue]);

  const handleOnChange = useCallback(
    ({ value }) => {
      const selectedCostCenter: CostCenter | undefined = value[0] as CostCenter;
      setCostCenter(selectedCostCenter);
      if (selectedCostCenter) {
        setValue("costCenterId", selectedCostCenter.id);
      } else {
        setValue("costCenterId", undefined);
      }
    },
    [setValue]
  );

  return (
    <Controller
      control={control}
      name="costCenterId"
      render={({ field: { value, onChange } }) => (
        <div>
          <Label className="block text-xs font-medium text-gray-700 truncate mb-1">
            Cost Center
          </Label>
          <Select
            size="mini"
            isLoading={loading}
            options={retrievedCostCenters}
            labelKey="name"
            valueKey="id"
            onChange={handleOnChange}
            value={costCenter ? [costCenter] : value}
            disabled={!endCustomerId}
          />
          {error && (
            <div className="text-red-500 mt-2 text-sm">{error.message}</div>
          )}
        </div>
      )}
    />
  );
}

export default CostCenterSelector;
